

/* Estilos para a área de login */
.login-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.login-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
  z-index: -1; /* Garante que a imagem fique atrás dos outros elementos */
}

/* Estilos para a mensagem de chamada do site */
.login-container h2 {
  position: absolute;
  top: 20%; /* Ajuste conforme necessário */
  left: 60%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: white; /* Ou qualquer outra cor desejada */
}

.login-container h4 {
  position: absolute;
  top: 35%; /* Ajuste conforme necessário */
  left: 20%;
  transform: translateX(-50%);
  font-size: 1rem;
  color: white; /* Ou qualquer outra cor desejada */
}

.login-message {
  margin-top: 2vh; /* Margem relativa */
  font-size: 2vw; /* Tamanho relativo */
  color: #ff0000;
}

/* Estilos para a área de mensagem do sistema */
.system-messageoff {
  height: registro.br80px;
  width: 100%;
  padding: 20px 20px;
  background-color: #ff6f00;
  color: white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra para destacar a mensagem */
}

.system-messageoff.hidden {
  opacity: 0;
}

.whatsapp-icon {
  width: 50px; /* Defina o tamanho desejado do ícone */
}

.background-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Definir a altura como 100% da altura da viewport */
  background-size: cover; /* Ajusta o tamanho da imagem de fundo para cobrir todo o contêiner */
}

.whatsapp-link {
  position: fixed;
  bottom: 70px;
  right: 70px;
}

.whatsapp-link img {
  width: 50px;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para garantir que a imagem de fundo cubra todo o contêiner */
  z-index: 0;
}