/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #555;
  color: #333;
}

.container {
  position: relative;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebar.open {
  left: 0; /* Show the sidebar */
}

.sidebar.closed {
  left: -210px; /* Hide the sidebar */
}

.sidebar img {
  margin-bottom: 20px;
  border-radius: 5px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.sidebar ul li {
  margin-bottom: 15px;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.sidebar ul li a:hover {
  color: #ff6f00;
}

.content {
  flex: 1;
  padding: 50px;
  margin-right: 50; /* Default margin */
  transition: margin-right 0.3s ease; /* Smooth transition */
}

.content.shifted {
  margin-left: 250px; /* Adjust margin when the sidebar is open */
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-gap: 20px;
}

.card {
 display: flex;
  margin-top: -40px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;

}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fotosair {
 display: relative;
  margin-left: 180px;
  margin-top: -30px;
  padding: 10px;
}


.card h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-right: 40%;
}

.toggle-button {
  display: relative;
  align-items: center;
  justify-content: center;
  width: 40px; /* ajuste conforme necessário */
  height: 150px; /* ajuste conforme necessário */
  color: white;
  background-color: #FE0000; /* ajuste conforme necessário */
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute; /* Para garantir que o botão esteja fixado */
  top: 50%; /* Centraliza verticalmente */
  transform: translateY(-50%); /* Ajuste para centralização */
}

.toggle-button:hover {
  background-color: #4CAF50; /* ajuste conforme necessário */
}

.qr-code {
  color:#000;
  text-align: center;
  padding: 10px;
  border: 1px solid #000                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ;
  border-radius: 5px;
  background-color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.RiRobot2Line {
  margin-bottom: 20px;
}

.textarea-container {
  position:relative;
  margin-top: 20px;
}

.chat-textarea {
  width: 83%;
  height: 450px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 0.5px solid #ccc;
  resize: none;
  margin-bottom: 10px; /* Adiciona margem inferior para separar o textarea do botão */
}
.button {
  position: absolute;
  font-size: 13px;
  background-color: #4CAF50; /* Cor de fundo */
  color: white; /* Cor do texto */
  border: none;
  padding: 10px 20px; /* Espaçamento interno */
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Primeiro botão */
.button.adicionar {
  top: 100px; /* Ajusta a posição do primeiro botão */
  right: 0;
  width:15%;
}

/* Segundo botão */
.button.editar {
  top: 150px; /* Ajusta a posição do segundo botão */
  width:15%;
  right: 0;
}

/* Segundo botão */
.button.salvar {
  top: 200px; /* Ajusta a posição do segundo botão */
  width:15%;
  right:0;
}


.button:hover {
  background-color: #FE0000; /* Cor de fundo no hover */
}


.chat-textarea:focus {
  border-color: #ff6f00;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    left: -200px; /* Initially hidden */
  }

  .sidebar.open {
    left: 0; /* Show the sidebar */
  }

  .sidebar.closed {
    left: -200px; /* Hide the sidebar */
  }

  .content.shifted {
    margin-left: 200px; /* Adjust margin when the sidebar is open */
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 150px;
    left: -150px; /* Initially hidden */
  }

  .sidebar.open {
    left: 0; /* Show the sidebar */
  }

  .sidebar.closed {
    left: -150px; /* Hide the sidebar */
  }

  .content.shifted {
    margin-left: 150px; /* Adjust margin when the sidebar is open */
  }

  .toggle-button {
    padding: 5px 10px;
  }


}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.circle-container {
   margin-left: 205px;
}
.circle-container p {
   margin-top: 5px;
   text-align: center;
}


.toggle-button.expanded {
  margin-left: 250px;
}
/* Estilos para a área de mensagem do sistema */
.system-message {
  margin-left:15%;
  height:60px;
  width: 10%;
  padding: 20px 20px;
  background-color: #ff6f00;
  color: white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.system-message.hidden {
  opacity: 0;
}

/* Estilo para a foto do usuário */

.user-photo {
  display: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Use metade da largura/altura para fazer um círculo */
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease; /* Adiciona uma transição suave */
}

.user-photo img {
  width: 100%; /* Garante que a imagem preencha completamente o contêiner */
  height: 100%; /* Garante que a imagem preencha completamente o contêiner */
  object-fit: cover; /* Redimensiona a imagem para preencher o contêiner, mantendo sua proporção */
}
/* Estilo para o painel */
.painel {
  background-color: #f0f2f5; /* Cor de fundo semelhante ao do Facebook */
}

.login-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  z-index: -1; /* Garante que a imagem fique atrás dos outros elementos */
}
