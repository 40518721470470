.container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.header {
  width: 100%;
  max-width: 2300px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}

.logo {
  display: flex;
}

.logo img {

    width: auto;
    margin-left: 20px;

}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  margin: 0 15px;
}

.menu a {
  text-decoration: none;
  font-size: 18px;
  color: #333;
}

.menu a:hover {
  color: #007bff;
}

.login-button {
  font-size: 1.2em; /* Tamanho de fonte */
  padding: 27px 30px; /* Padding reduzido */
  background-color: #f04a6f; /* Cor de fundo */
  color: #fff; /* Cor do texto */
  border: none; /* Removida a borda */
  border-radius: 0px; /* Borda arredondada */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Transição suave */
  margin-right:0px;
}

.login-button p {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em; /* Ajuste o tamanho da fonte conforme necessário */
  color: #fff; /* Ajuste a cor do texto conforme necessário */
  margin: 0;

}

.login-button p:hover {
  color: #fff;
}